import styled from "@emotion/styled";
import { useInsight } from "../state/insight";
import { toast } from "react-toastify";
import { InsightIcon } from "./InsightIcon";
import { gameStateAtom } from "../state/gameStateAtom";
import { useRecoilState } from "recoil";
import React from "react";

export const InsightDisplay: React.FC = () => {
  const { insight, addInsight, insightAnimation, resetInsight } = useInsight();
  const [, setGameState] = useRecoilState(gameStateAtom);

  window.addInsight = (message: string, value: number, notes?: string) => {
    addInsight(message, value);
  };

  window.resetInsight = (rememberPrevios: boolean = true) => {
    setGameState((prev) => {
      if (rememberPrevios) {
        return {
          ...prev,
          dialogContext: {
            ...prev.dialogContext,
            Flags: {
              ...prev.dialogContext.Flags,
              insight: 0,
              bauInsight: insight.score,
            },
            insight: 0,
          },
        };
      }

      return {
        ...prev,
        dialogContext: {
          ...prev.dialogContext,
          Flags: {
            ...prev.dialogContext.Flags,
            insight: 0,
          },
          insight: 0,
        },
      };
    });
    resetInsight();
  };

  const isAnimating = !!insightAnimation;

  return (
    <InsightContainer>
      <InsightIcon />

      <MeterContainer>
        <Labels>
          <InsightLabel>Insight</InsightLabel>
          {isAnimating && (
            <InsightAddition>
              +{insight.messages[insight.messages.length - 1]}
            </InsightAddition>
          )}
        </Labels>
        <MeterWrapper>
          <Meter
            value={insight.score}
            color={isAnimating ? "#fff" : "#e8c547"}
          />
          {insight.score > 0 && (
            <ScoreLabel value={insight.score}>{insight.score}</ScoreLabel>
          )}
        </MeterWrapper>
      </MeterContainer>
    </InsightContainer>
  );
};

const Labels = styled.div`
  label: Labels;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
`;

const MeterContainer = styled.div`
  label: MeterContainer;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InsightAddition = styled.div`
  label: InsightLabel;
  color: #fff;
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: right;
`;
const InsightLabel = styled.div`
  label: InsightLabel;
  color: #fff;
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const InsightContainer = styled.div`
  label: InsightContainer;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

declare global {
  interface Window {
    resetInsight: (rememberPrevios: boolean) => void;
  }
}

const MeterWrapper = styled.div`
  label: MeterWrapper;
  position: relative;
`;

const ScoreLabel = styled.div<{ value: number }>`
  label: ScoreLabel;
  position: absolute;
  bottom: -15px;
  left: ${(props) => props.value}%;
  transition: left 1s;
  transform: translateX(-50%);
  background-color: #e8c547;
  color: #021d2a;
  font-family: "Noto Sans";
  font-size: 12px;
  padding: 2px 4px;
  border-radius: 2px;

  &::before {
    content: "";
    position: absolute;
    top: -4px;
    left: 50%;

    transform: translateX(-50%);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #e8c547;
  }
`;

const Meter = styled.div<{ value: number; color: string }>`
  width: 400px;
  height: 8px;
  background-color: #021d2a;
  position: relative;
  margin-top: 5px;
  margin-bottom: 15px; // Increased to make room for the label
  overflow: visible; // Changed to visible to show the label
  &:before {
    content: "";
    display: block;
    width: ${(props) => props.value}%;
    height: 100%;
    background-color: ${(props) => props.color};
    transition: width 1s;
  }
`;
