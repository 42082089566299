import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Nav, Container, Modal, Button } from "react-bootstrap";
import { List } from "react-bootstrap-icons";
import styled from "@emotion/styled";

const StyledNavbar = styled(Navbar)`
  position: fixed;
  bottom: 8px;
  right: 8px;
  z-index: 1000;
`;

const HamburgerMenu: React.FC = () => {
  const [expanded, setExpanded] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();

  //   const handleGameMenu = () => {
  //     navigate("/menu");
  //     setExpanded(false);
  //   };

  const handleLogoutClick = () => {
    setShowLogoutModal(true);
    setExpanded(false);
  };

  const handleLogoutConfirm = () => {
    setShowLogoutModal(false);
    navigate("/logout");
  };

  const handleLogoutCancel = () => {
    setShowLogoutModal(false);
  };

  return (
    <>
      <StyledNavbar bg="dark" variant="dark" expand="lg" expanded={expanded}>
        <Container>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(!expanded)}
          >
            <List size={24} />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {/* <Nav.Link onClick={handleGameMenu}>Game Menu</Nav.Link> */}
              <Nav.Link onClick={handleLogoutClick}>Logout</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </StyledNavbar>

      <Modal show={showLogoutModal} onHide={handleLogoutCancel}>
        <Modal.Header closeButton>
          <Modal.Title style={{ height: "30px" }}>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to logout?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleLogoutCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleLogoutConfirm}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HamburgerMenu;
