import { Dialog } from "./Dialog";

export interface DialogContext {
  DialogFlags: Record<string, any>;
  Flags: Record<string, any>;
  insight: number;
  trust: number;
}

export const initDialogContext = (dialog: Dialog, insight: number, trust: number): DialogContext => {
  const promptConditions = Object.values(dialog.nodes).map((node) =>
    node.prompts.map((prompt) => prompt.condition)
  );
  const responseConditions = Object.values(dialog.nodes).map((node) =>
    node.responses.map((response) => response.condition)
  );

  const allConditions = [...promptConditions, ...responseConditions]
    .flat()
    .map((condition) => condition.replaceAll(/!&|/g, "").split(/\W/))
    .flat()
    .map((condition) => condition.trim())
    .filter((condition) => condition !== "");

  const context = {
    DialogFlags: allConditions.reduce((acc, condition) => {
      acc[condition] = false;
      return acc;
    }, {} as Record<string, any>),
    Flags: {},
    insight,
    trust,
  };

  return context;
};

declare global {
  interface Window {
    DialogFlags: Record<string, any>;
    Flags: Record<string, any>;
  }
}
