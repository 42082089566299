import { Button, Table } from "react-bootstrap";
import { EMR } from "../data/emr";
import { EMRSection } from "./EMRSection";

export const EMRHistory: React.FC<{ data: EMR }> = ({ data }) => {
  return (
    <EMRSection title="Clinical encounter history">
      <Button disabled={true} style={{ margin: "50px 10px" }}>
        + Add Visit
      </Button>
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Department</th>
            <th>Reason</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          {data.history.map((item, i) => (
            <tr key={i}>
              <td>{item.date}</td>
              <td>{item.department}</td>
              <td>{item.reason}</td>
              <td>{item.comment}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </EMRSection>
  );
};
