import React from "react";
import { EMR } from "../data/emr";
import {
  Column,
  CommentsBox,
  DoubleColumns,
  Page,
  SubHeadNoBorder,
  SubHeadTopBorder,
} from "./EMRStyles";
import { EMRSection } from "./EMRSection";
import styled from "@emotion/styled";
import { EMRRadio } from "./EMRRadio";
import { IconProblem } from "./sdoh/IconProblem";
import { IconMonitor } from "./sdoh/IconMonitor";
import { IconOK } from "./sdoh/IconOK";
import { EMRToggle } from "./EMRToggle";
import EMRHighlight from "./EMRHighlight";

const Row = styled.div`
  border-bottom: 1px solid #cdd1c4;
  padding: 5px;
`;

const ScaleIcon = styled.div`
  transform: scale(0.6);
  display: inline-block;
`;
export const EMRWhatMatters: React.FC<{ data: EMR }> = ({ data }) => {
  return (
    <Page>
      <DoubleColumns>
        <Column style={{ flexBasis: "636px" }}>
          <EMRHighlight emrPath="what-matters">
            <EMRSection title="What Matters">
              <EMRHighlight emrPath="fourM.appearance">
                <Row>
                  <EMRRadio active={data.fourM.appearance === "younger"}>
                    <ScaleIcon>
                      <IconOK />
                    </ScaleIcon>
                    Appears younger than stated age
                  </EMRRadio>
                </Row>
                <Row>
                  <EMRRadio active={data.fourM.appearance === "same"}>
                    <ScaleIcon>
                      <IconMonitor />
                    </ScaleIcon>
                    Appears stated age
                  </EMRRadio>
                </Row>
                <Row>
                  <EMRRadio active={data.fourM.appearance === "older"}>
                    <ScaleIcon>
                      <IconProblem />
                    </ScaleIcon>
                    Appears older than stated age
                  </EMRRadio>
                </Row>
              </EMRHighlight>
              <SubHeadNoBorder style={{ marginTop: 10 }}>
                ACP Status
              </SubHeadNoBorder>
              <div style={{ marginBottom: 10 }}>
                <ScaleIcon>
                  <IconOK />
                </ScaleIcon>
                On file
              </div>

              {/* TODO: Make this dynamic */}
              <SubHeadTopBorder>"What Matters"</SubHeadTopBorder>
              <DoubleColumns style={{ padding: 10 }}>
                <Column>
                  <EMRHighlight emrPath="fourM.whatMatters.relationships">
                    <EMRToggle
                      autoLabel={false}
                      active={data.fourM.whatMatters.relationships}
                    >
                      Relationships
                    </EMRToggle>
                  </EMRHighlight>

                  <EMRHighlight emrPath="fourM.whatMatters.healthAndWellBeing">
                    <EMRToggle
                      autoLabel={false}
                      active={data.fourM.whatMatters.healthAndWellBeing}
                    >
                      Health & well-being
                    </EMRToggle>
                  </EMRHighlight>
                  <EMRHighlight emrPath="fourM.whatMatters.personalGrowthAndDevelopment">
                    <EMRToggle
                      autoLabel={false}
                      active={
                        data.fourM.whatMatters.personalGrowthAndDevelopment
                      }
                    >
                      Personal growth & development
                    </EMRToggle>
                  </EMRHighlight>
                  <EMRHighlight emrPath="fourM.whatMatters.purposeAndMeaning">
                    <EMRToggle
                      autoLabel={false}
                      active={data.fourM.whatMatters.purposeAndMeaning}
                    >
                      Purpose & meaning
                    </EMRToggle>
                  </EMRHighlight>
                  <EMRHighlight emrPath="fourM.whatMatters.financialSecurity">
                    <EMRToggle
                      autoLabel={false}
                      active={data.fourM.whatMatters.financialSecurity}
                    >
                      Financial security
                    </EMRToggle>
                  </EMRHighlight>
                  <EMRHighlight emrPath="fourM.whatMatters.communityAndSocialConnections">
                    <EMRToggle
                      autoLabel={false}
                      active={
                        data.fourM.whatMatters.communityAndSocialConnections
                      }
                    >
                      Community & social connections
                    </EMRToggle>
                  </EMRHighlight>
                </Column>
                <Column>
                  <EMRHighlight emrPath="fourM.whatMatters.passionsAndHobbies">
                    <EMRToggle
                      autoLabel={false}
                      active={data.fourM.whatMatters.passionsAndHobbies}
                    >
                      Passions & hobbies
                    </EMRToggle>
                  </EMRHighlight>
                  <EMRHighlight emrPath="fourM.whatMatters.spiritualityAndBeliefs">
                    <EMRToggle
                      autoLabel={false}
                      active={data.fourM.whatMatters.spiritualityAndBeliefs}
                    >
                      Spirituality & beliefs
                    </EMRToggle>
                  </EMRHighlight>
                  <EMRHighlight emrPath="fourM.whatMatters.environmentAndSustainability">
                    <EMRToggle
                      autoLabel={false}
                      active={
                        data.fourM.whatMatters.environmentAndSustainability
                      }
                    >
                      Environment & sustainability
                    </EMRToggle>
                  </EMRHighlight>
                  <EMRHighlight emrPath="fourM.whatMatters.freedomAndAutonomy">
                    <EMRToggle
                      autoLabel={false}
                      active={data.fourM.whatMatters.freedomAndAutonomy}
                    >
                      Freedom & autonomy
                    </EMRToggle>
                  </EMRHighlight>
                  <EMRHighlight emrPath="fourM.whatMatters.legacyAndImpact">
                    <EMRToggle
                      autoLabel={false}
                      active={data.fourM.whatMatters.legacyAndImpact}
                    >
                      Legacy & impact
                    </EMRToggle>
                  </EMRHighlight>
                  <EMRHighlight emrPath="fourM.whatMatters.painManagement">
                    <EMRToggle
                      autoLabel={false}
                      active={data.fourM.whatMatters.painManagement}
                    >
                      Pain management
                    </EMRToggle>
                  </EMRHighlight>
                </Column>
              </DoubleColumns>
              <SubHeadTopBorder>Notes</SubHeadTopBorder>
              <CommentsBox>
                {data.fourM.whatMatters.comments
                  .split("\n")
                  .map((comment, index) => (
                    <div key={index}>{comment.trim()}</div>
                  ))}
              </CommentsBox>
            </EMRSection>
          </EMRHighlight>
        </Column>
        <Column style={{ flexBasis: "400px" }}>
          <EMRHighlight emrPath="medication-mentation-mobility">
            <EMRSection title="Medication">
              <Row>
                <EMRRadio active={data.fourM.medication.pim === "none"}>
                  <ScaleIcon>
                    <IconOK />
                  </ScaleIcon>
                  No PIMs
                </EMRRadio>
              </Row>
              <Row>
                <EMRRadio active={data.fourM.medication.pim === "1"}>
                  <ScaleIcon>
                    <IconMonitor />
                  </ScaleIcon>
                  1 PIM
                </EMRRadio>
              </Row>
              <Row>
                <EMRRadio active={data.fourM.medication.pim === "more"}>
                  <ScaleIcon>
                    <IconProblem />
                  </ScaleIcon>
                  2 or more PIMs
                </EMRRadio>
              </Row>
            </EMRSection>
            <EMRSection title="Mentation">
              <Row>
                <EMRRadio active={data.fourM.mentation.slums === "normal"}>
                  <ScaleIcon>
                    <IconOK />
                  </ScaleIcon>
                  Normal SLUMS / Negative PHQ
                </EMRRadio>
              </Row>
              <Row>
                <EMRRadio active={data.fourM.mentation.slums === "mild"}>
                  <ScaleIcon>
                    <IconMonitor />
                  </ScaleIcon>
                  SLUMS 20-24 / Positive PHQ
                </EMRRadio>
              </Row>
              <Row>
                <EMRRadio active={data.fourM.mentation.slums === "positive"}>
                  <ScaleIcon>
                    <IconProblem />
                  </ScaleIcon>
                  SLUMS under 20 / Positive PHQ
                </EMRRadio>
              </Row>
            </EMRSection>
            <EMRSection title="Mobility">
              <Row>
                <EMRRadio active={data.fourM.mobility.gait === "high"}>
                  <ScaleIcon>
                    <IconOK />
                  </ScaleIcon>
                  Gait speed more than 1m/s
                </EMRRadio>
              </Row>
              <Row>
                <EMRRadio active={data.fourM.mobility.gait === "medium"}>
                  <ScaleIcon>
                    <IconMonitor />
                  </ScaleIcon>
                  Gait speed 0.8 - 1.0 m/s
                </EMRRadio>
              </Row>
              <Row>
                <EMRRadio active={data.fourM.mobility.gait === "low"}>
                  <ScaleIcon>
                    <IconProblem />
                  </ScaleIcon>
                  Gait speed less than 0.8 m/s
                </EMRRadio>
              </Row>
            </EMRSection>
          </EMRHighlight>
        </Column>
      </DoubleColumns>
    </Page>
  );
};
