import { Medication } from "../../data/emr";
import styled from "@emotion/styled";
import { Iconcheck } from "../IconCheck";
import { IconWarning } from "../IconWarning";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useRef } from "react";

const Item = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.45rem 1rem 0.45rem 3rem;
  border-bottom: 1px solid #cdd1c4;
  position: relative;

  svg {
    position: absolute;
    left: 1rem;
    top: 10px;
  }
`;

const MedicationIcon: React.FC<{ status: Medication["status"] }> = ({
  status,
}) => {
  
  if (status === "ok") {
    return <Iconcheck />;
  } else if (status === "refill") {
    return (
      <OverlayTrigger
        trigger="hover"
        
        placement="top"
        overlay={<Tooltip id="tooltip">Needs Refill</Tooltip>}
      >
        <IconWarning />
      </OverlayTrigger>
    );
  }
  return null;
};

export const EMRMedications: React.FC<{ medications: Medication[] }> = ({
  medications,
}) => {
  return (
    <>
      {medications.map((medication, index) => {
        return (
          <Item key={index}>
            <MedicationIcon status={medication.status} />
            {medication.name}
          </Item>
        );
      })}
    </>
  );
};
