import React from "react";
import { DialogContext } from "../dialog/DialogContext";
import { Button, Modal } from "react-bootstrap";
import bg from "./no_ceo_1280.jpg";
import styled from "@emotion/styled";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${bg});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TutorialPrompt: React.FC<{
  onComplete: (
    context?: DialogContext,
    dialogLog?: string[],
    nextStep?: string
  ) => void;
  allowSkip?: boolean;
  dialogContext: DialogContext;
  skipNode: string;
  tutorialNode: string;
  prompt: string;
}> = ({
  onComplete,
  dialogContext,
  skipNode,
  tutorialNode,
  prompt,
  allowSkip,
}) => {
  const onTutorialClick = () => {
    onComplete(undefined, [], tutorialNode);
  };
  const onSkipClick = () => {
    onComplete(undefined, [], skipNode);
  };
  return (
    <Container>
      <Modal show={true} centered>
        <Modal.Header>
          <Modal.Title style={{ height: "30px" }}>Tutorial</Modal.Title>
        </Modal.Header>
        <Modal.Body>{prompt}</Modal.Body>
        <Modal.Footer>
          {allowSkip && (
            <Button variant="secondary" onClick={onSkipClick}>
              Skip Tutorial
            </Button>
          )}
          <Button variant="primary" autoFocus onClick={onTutorialClick}>
            Start
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

//   <button onClick={onTutorialClick}>Tutorial</button>
//   <button onClick={onSkipClick}>Skip</button>
