import { Dialog, DialogNode, DialogPrompt, DialogResponse } from "./Dialog";
import { DialogRow, DialogSheet } from "./DialogSheet";

const importPrompt = (row: DialogRow): DialogPrompt => {

  return {
    txt: row.Prompts!,
    action: row.Actions || "",
    postVideoAction: row["Post Video Actions"] || "",
    condition: row.Conditions || "",
    video: row.Video || "",
  };
};

const importResponse = (row: DialogRow): DialogResponse => {
  return {
    txt: row.Responses!,
    condition: row.Conditions || "",
    nextNode: row["Go To"] || "",
    action: row.Actions || "",
    category: row["4Ms Type"],
  };
};

const isPromptRow = (row: DialogRow) => row.Prompts && row.Prompts.length > 0;

const isResponseRow = (row: DialogRow) =>
  row.Responses && row.Responses.length > 0;

const importNode = (rows: DialogRow[]): DialogNode => {
  const prompts: DialogPrompt[] = rows.filter(isPromptRow).map(importPrompt);

  const responses: DialogResponse[] = rows
    .filter(isResponseRow)
    .map(importResponse);
  return {
    id: rows[0].Node!,
    npc: rows[0].NPC!,
    prompts: prompts,
    responses: responses,
  };
};

const trimHeader = (rows: DialogRow[]) => {
  let i = 0;
  for (const row of rows) {
    if (row.Node && row.Prompts && row.Node !== "Node") {
      return rows.slice(i);
    }
    i++;
  }
  return rows;
};

export const dialogImport = (
  data: DialogSheet
): { out: Dialog; errors: string[] } => {
  console.log("Importing dialog", data);
  if (!data || !data.data) {
    console.error("Invalid data structure for dialog import:", data);
    return { out: { id: "", firstNode: "", nodes: {} }, errors: ["Invalid data structure"] };
  }
  const rows = trimHeader(data.data);

  const out: Dialog = {
    id: data.id,
    firstNode: rows[0].Node || "",
    nodes: {},
  };

  const errors: string[] = [];

  // First pass, find all the related rows that are separated by a node ID
  const nodes: Record<string, DialogRow[]> = {};
  let currentNode = "";
  for (const row of rows) {
    if (row.Node) {
      currentNode = row.Node;
      nodes[currentNode] = [];
    }
    if (currentNode) {
      nodes[currentNode].push(row);
    }
  }

  out.nodes = Object.values(nodes)

    .map(importNode)
    .reduce((acc, node) => {
      acc[node.id] = node;
      return acc;
    }, {} as Record<string, DialogNode>);

  return { out, errors };
};
