import React, { forwardRef } from "react";

export const IconWarning = forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement>
>((props, ref) => (
  <svg
    height="16"
    width="16"
    fill="none"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M1.46667 14.3238C1.2 14.3238 1.00317 14.2095 0.876191 13.9809C0.749206 13.7524 0.749206 13.5238 0.876191 13.2952L7.39048 2.01903C7.53016 1.79046 7.73333 1.67618 8 1.67618C8.26667 1.67618 8.46984 1.79046 8.60952 2.01903L15.1238 13.2952C15.2508 13.5238 15.2508 13.7524 15.1238 13.9809C14.9968 14.2095 14.8 14.3238 14.5333 14.3238H1.46667ZM8 6.47618C7.83492 6.47618 7.69854 6.53002 7.59086 6.6377C7.48267 6.74589 7.42857 6.88253 7.42857 7.04761V9.71427C7.42857 9.86665 7.48267 9.99999 7.59086 10.1143C7.69854 10.2286 7.83492 10.2857 8 10.2857C8.16508 10.2857 8.30171 10.2286 8.4099 10.1143C8.51759 9.99999 8.57143 9.86665 8.57143 9.71427V7.04761C8.57143 6.88253 8.51759 6.74589 8.4099 6.6377C8.30171 6.53002 8.16508 6.47618 8 6.47618ZM8 12.2667C8.17778 12.2667 8.32381 12.2095 8.4381 12.0952C8.55238 11.9809 8.60952 11.8349 8.60952 11.6571C8.60952 11.4794 8.55238 11.3333 8.4381 11.219C8.32381 11.1047 8.17778 11.0476 8 11.0476C7.82222 11.0476 7.67619 11.1047 7.5619 11.219C7.44762 11.3333 7.39048 11.4794 7.39048 11.6571C7.39048 11.8349 7.44762 11.9809 7.5619 12.0952C7.67619 12.2095 7.82222 12.2667 8 12.2667ZM2.24762 13.1809H13.7524L8 3.27618L2.24762 13.1809Z"
      fill="#DCB011"
    />
  </svg>
));

IconWarning.displayName = "IconWarning";
