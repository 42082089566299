import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { useSavedGame } from "../../simulation/useSavedGames";
import { ResetGameModal } from "./ResetGameModal";

import geriactBackground from "./geriact-title-screen.jpg";

import { gameStateAtom, initialGameState } from "../../state/gameStateAtom";
import { useRecoilState } from "recoil";
import { archiveGameState, saveGameState } from "../../state/saveGameService";
import HamburgerMenu from "../HamburgerMenu";

const FullScreenDiv = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${geriactBackground});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding-top: 100px;
  overflow: hidden;
`;

export const StartScreen = () => {
  const navigate = useNavigate();
  const savedGames = useSavedGame();
  const [showResetModal, setShowResetModal] = useState(false);
  const [, setGameState] = useRecoilState(gameStateAtom);

  const play = useCallback(() => {
    //dispatch(initializeSimulation());
    navigate("/menu");
  }, [navigate]);

  const handleResetClick = useCallback(() => {
    setShowResetModal(true);
  }, []);

  const confirmReset = useCallback(() => {
    archiveGameState(savedGames.gameState)
      .then(() => saveGameState(initialGameState))
      .then(() => {
        setGameState(initialGameState);
        setShowResetModal(false);
        navigate("/menu");
      });
  }, [navigate, savedGames.gameState, setGameState]);

  const cancelReset = useCallback(() => {
    setShowResetModal(false);
  }, []);

  if (savedGames.loading) {
    return <div>Loading start screen...</div>;
  }

  const hasSavedGame =
    savedGames.gameState.currentStage !== "core1" ||
    savedGames.gameState.stages.some((stage) => stage.completed);

  const StartGameButton = styled.button`
    background-color: rgba(2, 17, 27, 0.8);
    padding: 15px 50px; // Reduced height, increased width
    color: white;
    border-radius: 3px;
    font-size: 3em;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease; // Smooth transition for hover effect
    width: 350px;

    &:hover {
      background-color: rgba(
        4,
        34,
        54,
        1
      ); // Slightly lighter and more opaque on hover
    }
  `;

  const ResetGameButton = styled(StartGameButton)`
    background-color: rgba(2, 17, 27, 0.8);
    padding: 15px 50px; // Reduced height, increased width
    color: white;
    border-radius: 3px;
    font-size: 3em;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease; // Smooth transition for hover effect
  `;

  const startLabel = hasSavedGame ? "Continue" : "Start";

  return (
    <>
      <FullScreenDiv>
        <HamburgerMenu />
        <StartGameButton onClick={play} type="button">
          {startLabel}
        </StartGameButton>
        {hasSavedGame && (
          <ResetGameButton onClick={handleResetClick} type="button">
            New Game
          </ResetGameButton>
        )}
      </FullScreenDiv>
      <ResetGameModal
        show={showResetModal}
        onHide={cancelReset}
        onConfirm={confirmReset}
      />
    </>
  );
};
