import React from "react";
import styled from "@emotion/styled";
import {
  ActionHistory,
  ConditionHistory,
  DialogHistory,
  History,
  mergeInsight,
} from "./useDialog";
import { DialogContext } from "./DialogContext";
import { useToggle } from "../util/useToggle";
import { useInsight } from "../state/insight";

const LogContainer = styled.div({
  label: "LogContainer",
  position: "absolute",
  top: 150,
  right: 0,
  width: 400,
  bottom: 280,
  padding: 10,
  borderRadius: 10,
  backgroundColor: "#fff",
  overflowY: "auto",
  border: "1px solid #eee",
});

const CloseButton = styled.button({
  label: "CloseButton",
  position: "absolute",
  top: 5,
  right: 5,
  padding: 5,
});

const CheckboxContainer = styled.div({
  label: "CheckboxContainer",
  display: "flex",
  alignItems: "center",
  margin: 10,
});

const StyledCheckbox = styled.input({
  label: "StyledCheckbox",
  marginRight: 8,
});

export const DialogLog: React.FC<{
  history: History[];
  context: DialogContext;
  toggleWaitForVideo: (doNotWaitForVideo: boolean) => void;
  doNotWaitForVideo: boolean;
  setAltCategoryMode: (altCategoryMode: boolean) => void;
  altCategoryMode: boolean;
}> = ({
  history,
  context,
  toggleWaitForVideo,
  doNotWaitForVideo,
  setAltCategoryMode,
  altCategoryMode,
}) => {
  const [hidden, toggleHidden] = useToggle();
  const { insight } = useInsight();

  if (hidden) return null;
  return (
    <LogContainer>
      <CloseButton onClick={toggleHidden}>X</CloseButton>
      <div className="card-body">
        <h5 className="card-title">Conversation Log</h5>
        <CheckboxContainer>
          <StyledCheckbox
            type="checkbox"
            id="doNotWaitForVideo"
            checked={doNotWaitForVideo}
            onChange={(e) => toggleWaitForVideo(e.target.checked)}
          />
          <label htmlFor="doNotWaitForVideo">Do not wait for video</label>
        </CheckboxContainer>
        <CheckboxContainer>
          <StyledCheckbox
            type="checkbox"
            id="altCategoryMode"
            checked={altCategoryMode}
            onChange={(e) => setAltCategoryMode(e.target.checked)}
          />
          <label htmlFor="altCategoryMode">Alt Category Mode</label>
        </CheckboxContainer>

        {history?.map((h: History, i: number) => (
          <div key={i}>
            {h.type === "dialog" && <PromptHistory history={h} />}
            {h.type === "action" && <ActionHistoryLine history={h} />}
            {h.type === "condition" && <ConditionHistoryLine history={h} />}
          </div>
        ))}
        <hr />
        <pre>{JSON.stringify(mergeInsight(context, insight), null, 2)}</pre>
      </div>
    </LogContainer>
  );
};

const Response = styled.div({
  textAlign: "right",
});

const ChatBubble = styled.div(({ color }) => ({
  display: "inline-block",
  width: "80%",
  border: "1px solid #eee",
  padding: 9,
  textAlign: "left",
  borderRadius: 6,
  color: "#fff",
  backgroundColor: color,
  margin: 5,
}));

const Condition = styled.pre<{ $result: boolean }>((props) => ({
  color: props.$result ? "green" : "red",
}));

const PromptHistory: React.FC<{ history: DialogHistory }> = ({ history }) => {
  return (
    <>
      <label>{history.nodeId}</label>
      <br />
      <ChatBubble color="#6c757d">
        <b>{history.npc}</b>: {history.prompt}
      </ChatBubble>
      <Response>
        <ChatBubble color="#007bff">{history.response}</ChatBubble>
      </Response>
    </>
  );
};

const ActionHistoryLine: React.FC<{ history: ActionHistory }> = ({
  history,
}) => {
  return (
    <pre>
      &gt; {history.action}
      {history.error && (
        <span style={{ color: "red" }}> ERROR: {history.error}</span>
      )}
    </pre>
  );
};

const ConditionHistoryLine: React.FC<{ history: ConditionHistory }> = ({
  history,
}) => {
  return (
    <Condition $result={history.result}>{String(history.condition)}</Condition>
  );
};
