import { on } from "events";
import { DialogLoader } from "../dialog/DialogLoader";
import { GameStage } from "../state/gameStateAtom";
import GameCompleteScreen from "./GameCompleteDialog";
import { TutorialPrompt } from "./TutorialPrompt";

export const gameStages: GameStage[] = [
  {
    id: "core1",
    label: "Introduction",
    steps: [
      {
        id: "core1-step1",
        label: "Tutorial Prompt",
        component: (onComplete, dialogContext) => (
          <TutorialPrompt
            dialogContext={dialogContext}
            onComplete={onComplete}
            tutorialNode="core1-step2"
            allowSkip={false}
            skipNode="core1-step3"
            prompt="A quick tutorial on the basics. Tutorials aren't skippable in this Beta test."
          />
        ),
      },
      {
        id: "core1-step2",
        label: "Tutorial",
        component: (onComplete, dialogContext) => (
          <DialogLoader
            key="cmo_tutorial"
            sheetId="cmo_tutorial"
            idleSrc="/dialog_videos/cmo/idle.mp4"
            initialContext={dialogContext}
            onComplete={onComplete}
          />
        ),
      },

      {
        id: "core1-step3",
        label: "CMO Introduction",
        component: (onComplete, dialogContext) => (
          <DialogLoader
            key="cmo_001_revise"
            sheetId="cmo_001_revise"
            idleSrc="/dialog_videos/cmo/idle.mp4"
            initialContext={dialogContext}
            onComplete={onComplete}
          />
        ),
      },
    ],
  },
  {
    id: "core2",
    label: "Asking what matters",
    steps: [
      {
        id: "core2-step1",
        label: "Mger Business as Usual",
        component: (onComplete, dialogContext) => (
          <DialogLoader
            key="mger_bau"
            sheetId="mger_bau"
            idleSrc="/dialog_videos/geron/geron_idle.mp4"
            initialContext={dialogContext}
            onComplete={onComplete}
          />
        ),
      },
      {
        id: "core2-tutorialprompt",
        label: "Tutorial Prompt",
        component: (onComplete, dialogContext) => (
          <TutorialPrompt
            dialogContext={dialogContext}
            onComplete={onComplete}
            skipNode="core2-step2"
            allowSkip={false}
            tutorialNode="core2-tutorial1"
            prompt="Adding insight and the patient chart."
          />
        ),
      },
      {
        id: "core2-tutorial1",
        label: "Tutorial",
        component: (onComplete, dialogContext) => (
          <DialogLoader
            key="cmo_tutorial2"
            sheetId="cmo_tutorial2"
            idleSrc="/dialog_videos/cmo/idle.mp4"
            showPromptButtons={true}
            initialContext={dialogContext}
            onComplete={onComplete}
          />
        ),
      },
      {
        id: "core2-step2",
        label: "Donna Business as Usual",
        component: (onComplete, dialogContext) => (
          <DialogLoader
            key="donna_bau"
            sheetId="donna_bau"
            showPromptButtons={true}
            idleSrc="/dialog_videos/donna/donna_idle.mp4"
            initialContext={dialogContext}
            onComplete={onComplete}
          />
        ),
      },
      {
        id: "core2-step3",
        label: "Mger 002",
        component: (onComplete, dialogContext) => (
          <DialogLoader
            key="mger_002"
            sheetId="mger_002"
            idleSrc="/dialog_videos/geron/geron_idle.mp4"
            onComplete={onComplete}
            initialContext={dialogContext}
            show4MEmrTab={true}
          />
        ),
      },

      {
        id: "core2-tutorialprompt2",
        label: "Tutorial Prompt",
        component: (onComplete, dialogContext) => (
          <TutorialPrompt
            dialogContext={dialogContext}
            onComplete={onComplete}
            skipNode="core2-step4"
            allowSkip={false}
            tutorialNode="core2-tutorial2"
            prompt="Changes to response choices for the 4Ms."
          />
        ),
      },

      {
        id: "core2-tutorial2",
        label: "Tutorial",
        component: (onComplete, dialogContext) => (
          <DialogLoader
            key="cmo_tutorial3"
            sheetId="cmo_tutorial3"
            idleSrc="/dialog_videos/cmo/idle.mp4"
            show4MEmrTab={true}
            showPromptButtons={true}
            initialContext={dialogContext}
            onComplete={onComplete}
          />
        ),
      },

      {
        id: "core2-step4",
        label: "Donna 4M Welless",
        component: (onComplete, dialogContext) => (
          <DialogLoader
            key="donna_4m_wellness"
            sheetId="donna_4m_wellness"
            showPromptButtons={true}
            show4MEmrTab={true}
            idleSrc="/dialog_videos/donna/donna_idle.mp4"
            initialContext={dialogContext}
            onComplete={onComplete}
          />
        ),
      },
      {
        id: "core2-step5",
        label: "Mger 003",
        component: (onComplete, dialogContext) => (
          <DialogLoader
            key="mger_003"
            sheetId="mger_003"
            idleSrc="/dialog_videos/geron/geron_idle.mp4"
            initialContext={dialogContext}
            onComplete={onComplete}
          />
        ),
      },
      {
        id: "core2-step6",
        label: "CMO 002",
        component: (onComplete, dialogContext) => (
          <DialogLoader
            key="cmo_002"
            sheetId="cmo_002"
            idleSrc="/dialog_videos/cmo/idle.mp4"
            initialContext={dialogContext}
            onComplete={onComplete}
          />
        ),
      },
      {
        id: "core2-step7",
        label: "Game Complete",
        component: (onComplete) => (
          <GameCompleteScreen onComplete={onComplete} />
        ),
      },
    ],
  },
];
