import React, { useState, useEffect, useMemo } from "react";
import styled from "@emotion/styled";

import useGoogleSheets from "use-google-sheets";
import { DialogPlayer } from "./DialogPlayer";
import { dialogImport } from "./DialogImport";
import { DialogSheet } from "./DialogSheet";
import { DialogContext } from "./DialogContext";

interface DialogLoaderProps {
  sheetId: string;
  onComplete: (context: DialogContext) => void;
  initialContext?: DialogContext;
  showPromptButtons?: boolean;
  skipAllVoiceovers?: boolean;
  idleSrc: string;
  show4MEmrTab?: boolean;
}

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const DialogLoader: React.FC<DialogLoaderProps> = ({
  sheetId,
  initialContext,
  showPromptButtons,
  skipAllVoiceovers,
  idleSrc,
  show4MEmrTab,
  onComplete,
}) => {
  const { data, loading, error } = useGoogleSheets({
    apiKey: "AIzaSyBq0SkvM391pziy1cL01q_ZN5di68PAMkY",
    sheetId: "1Uvp3d3FUr8ED1eBHhN67RAqAGVZ9M7KAnDHhi7uubWc",
  });

  const sheets = data as DialogSheet[];

  const dialog = useMemo(() => {
    if (sheets.length === 0) {
      return null;
    }
    console.info("Importing dialog", sheetId, sheets);
    const { out, errors } = dialogImport(sheets.find((s) => s.id === sheetId)!);
    console.log("Imported dialog", out, errors);
    return errors.length > 0 ? null : out;
  }, [loading, data, sheets]);

  if (loading) {
    return <LoadingWrapper>Loading...</LoadingWrapper>;
  }

  if (error) {
    return <div>Error: {error.response.statusText}</div>;
  }

  if (!dialog) {
    return <div>No dialog</div>;
  }

  return (
    <DialogPlayer
      onCompleted={onComplete}
      dialog={dialog}
      showPromptButtons={!!showPromptButtons}
      idleSrc={idleSrc}
      initialContext={initialContext}
      skipAllVoiceovers={skipAllVoiceovers}
      show4MEmrTab={show4MEmrTab}
    />
  );
};
