import React from "react";
import styled from "@emotion/styled";
import { GameStage, GameState } from "../state/gameStateAtom";

const PopupContainer = styled.div`
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  border: 2px solid white;
  padding: 20px;
  z-index: 1000;
  max-height: 80vh;
  overflow-y: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
`;

const StageList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const StageItem = styled.li`
  margin-bottom: 10px;
`;

const StageButton = styled.button`
  display: block;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  text-align: left;
  font-size: 16px;

  &:hover {
    text-decoration: underline;
  }
`;

const IdText = styled.span`
  display: inline;
  color: #888;
  font-size: 12px;
  margin-top: 0px;
  margin-left: 15px;
  vertical-align: middle;
`;

interface CheatCodePopupProps {
  onClose: () => void;
  onStepSelect: (stageId: string, stepIndex: number) => void;
  gameStages: GameStage[];
  gameState: GameState;
}

const CheatCodePopup: React.FC<CheatCodePopupProps> = ({
  onClose,
  onStepSelect,
  gameStages,
  gameState,
}) => {
  return (
    <PopupContainer>
      <CloseButton onClick={onClose}>×</CloseButton>
      <h2 style={{ color: "white" }}>Select a stage and step:</h2>
      <StageList>
        {gameStages.map((stage) => (
          <StageItem key={stage.id}>
            <h3 style={{ color: "white" }}>{stage.label}</h3>
            {stage.steps.map((step, index) => (
              <div key={step.id}>
                <StageButton onClick={() => onStepSelect(stage.id, index)}>
                  {step.label}
                  <IdText>{step.id}</IdText>
                </StageButton>
              </div>
            ))}
          </StageItem>
        ))}
      </StageList>
      <DebugProps>
        {JSON.stringify(gameState.dialogContext, null, 2)}
      </DebugProps>
    </PopupContainer>
  );
};

const DebugProps = styled.pre`
  color: white;
  font-size: 12px;
  margin-top: 20px;
  max-height: 200px;
  overflow-y: auto;
`;

export default CheatCodePopup;
