import styled from "@emotion/styled";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  background: #f0f0f0;
  overflow: auto;
  position: relative;
  width: 100%;
  max-height: calc(100vh - 70px);
`;

export const DoubleColumns = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  min-height: 200px;
  justify-content: space-between;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
`;

export const CategoryName = styled.div`
  color: #4d5061;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 15px 0px;
`;

export const QuestionPrompt = styled.div`
  color: #000;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SDOHCategoryContainer = styled.div`
  margin-left: 20px;
`;

export const ButtonContainer = styled.div`
  display: inline-flex;
  background: #f0f0f0;
  flex-direction: column;
  gap: 0;
  margin: 10px 20px 40px 0px;
`;

export const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0;
`;

export const SDOHButton = styled.button<{ active: boolean }>`
  color: #000;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  cursor: default !important;
  font-weight: 700;
  line-height: normal;
  border: 1px solid #cdd1c4;
  background: ${(props) => (props.active ? "#cdd1c4" : "#fff")};
  padding: 0.45rem 1rem;
  border-left: none;
  cursor-events: none;

  &:first-of-type {
    border-left: 1px solid #cdd1c4;
  }
`;

export const Button = styled.button<{ active: boolean }>`
  color: #000;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 1px solid #cdd1c4;
  background: ${(props) => (props.active ? "#cdd1c4" : "#fff")};
  padding: 0.45rem 1rem;
  border-left: none;
  &:active {
    background: #cdd1c4;
  }
  &:first-of-type {
    border-left: 1px solid #cdd1c4;
  }
`;

export const ButtonGradientBar = styled.div`
  height: 3px;

  background: var(
    --SDOH-gradient,
    linear-gradient(90deg, #d34343 10%, #dcb011 50%, #4da100 90%)
  );
`;

export const SubHead = styled.div`
  color: #4d5061;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-bottom: 1px solid #cdd1c4;
  padding: 10px;
  text-transform: uppercase;
`;

export const SubHeadTopBorder = styled.div`
  color: #4d5061;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-top: 1px solid #cdd1c4;
  padding: 15px 10px 10px 10px;
  text-transform: uppercase;
`;

export const SubHeadNoBorder = styled.div`
  color: #4d5061;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 10px;
  text-transform: uppercase;
`;

export const CommentsBox = styled.div`
  border: 1px solid #cdd1c4;
  margin: 10px;
  padding: 10px;
  min-height: 100px;
`;
