import React, { useEffect } from "react";
import "./firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useNavigate } from "react-router-dom";

export const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const auth = firebase.auth();
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    auth.onAuthStateChanged((user) => {
      if (user) {
        auth.signOut().then(() => {
          navigate("/");
          setTimeout(() => {
            window.location.reload();
          }, 100);
        });
      }
    });
  }, [navigate]);

  return <div>Logged out</div>;
};
