import React, { useState, useCallback } from "react";
import { Overlay, Popover, Button } from "react-bootstrap";
import { useTourState } from "./TourController";
import styled from "@emotion/styled";

interface TourElementProps {
  tourId: string;
  placement?: "top" | "right" | "bottom" | "left";
  children: React.ReactNode;
  // Add a new prop for custom styles
  style?: React.CSSProperties;
}

const StyledPopover = styled(Popover)`
  .popover-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

// Add a new styled component for the full-screen shade
const FullScreenShade = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 1040; // Ensure it's below the Popover but above other content
`;

const TourElement: React.FC<TourElementProps> = ({
  tourId,
  placement = "right",
  children,
  style,
}) => {
  const { tourState, nextStep, closeTour } = useTourState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const isActive = tourState.element === tourId;
  const targetRef = React.useRef(null);

  //console.log("tourState", tourState);

  const isLastStep = tourState.isLastStep;

  const handleButtonClick = useCallback((action: () => void) => {
    setIsButtonDisabled(true);

    setTimeout(() => {
      action();
      setIsButtonDisabled(false);
    }, 100);
  }, []);

  // const handleBackgroundClick = useCallback(() => {
  //   if (isLastStep) {
  //     handleButtonClick(closeTour);
  //   } else {
  //     handleButtonClick(nextStep);
  //   }
  // }, [isLastStep, handleButtonClick, closeTour, nextStep]);

  return (
    <>
      <div ref={targetRef} style={style}>
        {children}
      </div>
      {isActive && <FullScreenShade />}
      <Overlay
        show={isActive}
        target={targetRef.current}
        placement={placement}
        transition={false}
      >
        {(props) => (
          <StyledPopover id={`tour-${tourId}`} {...props}>
            <Popover.Body>
              {tourState.text}
              {isLastStep ? (
                <Button
                  onClick={() => handleButtonClick(closeTour)}
                  variant="primary"
                  className="mt-2"
                  disabled={isButtonDisabled}
                >
                  Close
                </Button>
              ) : (
                <Button
                  onClick={() => handleButtonClick(nextStep)}
                  variant="primary"
                  className="mt-2"
                  disabled={isButtonDisabled}
                >
                  Next
                </Button>
              )}
            </Popover.Body>
          </StyledPopover>
        )}
      </Overlay>
    </>
  );
};

export default TourElement;
