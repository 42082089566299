import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";

interface TourStep {
    element: string;
    text: string;
}

const tours: Record<string, TourStep[]> = {
    dialogIntro: [
        {
            element: "dialogPrompt",
            text: "This is what the character onscreen is saying to you.",
        },
        {
            element: "dialogChoices",
            text: "Click on one of these choices to choose what you say.",
        },
    ],
    patientIntro: [
        {
            element: "emrButton",
            text: "Click to open the EMR view. Progress Notes will be automatically added to the Snapshot tab during your interview.",
        },
        {
            element: "transcriptButton",
            text: "Open a running transcript of the interview so far.",
        },
        // {
        //     element: "responseTimer",
        //     text: "Each response you make adds a minute. Time is not a consideration in this module, but will be later.A good target is 15 minutes or under.",
        // },
        {
            element: "insightMeter",
            text: "As you interview a patient, your Insight about them will increase. The Insight you gain will also influence how much Trust they have in you.",
        },
        {
            element: "dialogChoices",
            text: "Let's try it out.",
        }
    ],
    "4mIntro": [
        {
            element: "dialogChoices",
            text: "For a 4Ms interview, your responses are organized by the 4Ms category. You may have choices in all or only in one. Be mindful that you can switch from your current category as you proceed.",
        },
    ],
};

interface TourState {
    tourId: string | null;
    step: number;
    element: string;
    text: string;
    isLastStep: boolean;
}

declare global {
    interface Window {
        startTour: (tourId: string, delay?: number) => void;
    }
}

export const useTourEventListeners = () => {
    const { startTour } = useTourState();
    useEffect(() => {
        window.startTour = startTour;
    }, [startTour]);
};

export const useTourState = () => {
    const [tourState, setTourState] = useRecoilState(tourStateAtom);

    const startTour = useCallback((tourId: string, delay: number = 0) => {
        const tour = tours[tourId];
        setTimeout(() => {
            setTourState({
                tourId,
                step: 0,
                element: tour[0].element,
                text: tour[0].text,
                isLastStep: tour.length === 1,
            });
        }, delay);
    }, [setTourState]);

    const closeTour = useCallback(() => {
        console.log("closeTour");
        setTourState({
            tourId: null,
            step: 0,
            element: "",
            text: "",
            isLastStep: false,
        });
    }, [setTourState]);

    const nextStep = useCallback(() => {
        if (!tourState?.tourId) return;
        console.log("nextStep");


        setTourState(tourState => {
            if (!tourState.tourId) return tourState;
            const tour = tours[tourState.tourId];
            return {
                tourId: tourState.tourId,
                step: tourState.step + 1,
                element: tour[tourState.step + 1].element,
                text: tour[tourState.step + 1].text,
                isLastStep: tour.length === tourState.step + 2,
            }
        });
    }, [tourState, setTourState]);

    return { tourState, startTour, nextStep, closeTour };
};

export const tourStateAtom = atom<TourState>({
    key: "tourState",
    default: {
        tourId: null,
        step: 0,
        element: "",
        text: "",
        isLastStep: false,
    },
});
