import React, { ReactNode, useState } from "react";
import * as DialogTypes from "./Dialog";
import { CategoryResponses } from "./useDialog";
import { ResponseBox } from "./ResponseBox";
import { FiveM, FiveMNames } from "../five-ms";
import styled from "@emotion/styled";
import { MattersIcon } from "./MattersIcon";
import { MedicationIcon } from "./MedicationIcon";
import { MobilityIcon } from "./MobilityIcon";
import { MentationIcon } from "./MentationIcon";
import { atom, useRecoilState } from "recoil";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const TabBar = styled.div`
  display: flex;
  gap: 2px;
  height: 57px;
  width: 100%;
  margin-bottom: 3px;
  margin-top: 3px;
`;

const TabButton = styled.button<{
  active: boolean;
  disabled: boolean;
  highlight: boolean;
}>`
  border: none;
  color: ${(props) => (props.disabled ? "#839095" : "#fff")};
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  background: ${(props) => (props.highlight ? "#116d9d" : "#051e2b")};
  padding: 13px 8px;
  outline: none;
  flex: ${(props) => (props.active ? "2" : "1")};
  border-bottom: ${(props) => props.active && "3px solid #fff"};
  svg {
    margin-right: 8px;
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};\
  }
  &:active {
    outline: none;
    border: 3px solid #011e2d;
  }
  &:hover {
    background-color: ${(props) => (props.disabled ? "#051e2b" : "#116d9d")};
  }
`;

const IconHolder = styled.div`
  width: 45px;
  max-width: 45px;
  min-width: 45px;
  flexshrink: 0;
  flexgrow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  responses: CategoryResponses;
  onResponse: (response: DialogTypes.DialogResponse, idx: number) => void;
  altCategoryMode: boolean;
}

const categoryAtom = atom<FiveM | null>({
  key: "category",
  default: null,
});

export const CategoryResponsesPrompt: React.FC<Props> = (props) => {
  if (!props.altCategoryMode) {
    return <TabbedCategoryResponsePrompt {...props} />;
  }

  return <CategoryResponsePrompt {...props} />;
};

const icons = {
  matters: <MattersIcon />,
  medication: <MedicationIcon />,
  mobility: <MobilityIcon />,
  mentation: <MentationIcon />,
};

const CategoryResponsePrompt: React.FC<Props> = (props) => {
  return (
    <div>
      {Object.keys(props.responses).map((category) => (
        <>
          {/* <div key={category}>{FiveMNames[category as FiveM]}:</div> */}
          {props.responses[category as FiveM]?.map((response, idx) => (
            <ResponseBox
              key={idx}
              onClick={() => props.onResponse(response, idx)}
            >
              <OverlayTrigger
                trigger="hover"
                placement="right"
                overlay={
                  <Tooltip id="tooltip">
                    {FiveMNames[category as FiveM]}
                  </Tooltip>
                }
              >
                <IconHolder>{icons[category as FiveM]}</IconHolder>
              </OverlayTrigger>
              {response.txt}
            </ResponseBox>
          ))}
        </>
      ))}
    </div>
  );
};

const TabbedCategoryResponsePrompt: React.FC<Props> = ({
  responses,
  onResponse,
  altCategoryMode,
}) => {
  const [_category, setCategory] = useRecoilState(categoryAtom);
  const [animationRan, setAnimationRan] = useState(false);
  const [highlightCat, setHighlightCat] = useState<FiveM | null>(null);

  if (Object.keys(responses).length === 0) {
    return null;
  }

  const currentResponses: DialogTypes.DialogResponse[] =
    (_category === null ? [] : responses[_category]!) || [];

  const category = currentResponses.length > 0 ? _category : null; // Set a null category if the current one has no responses

  if (!animationRan) {
    setAnimationRan(true);
    const highlightNext = (previous: FiveM | null) => {
      const categories: FiveM[] = [
        "matters",
        "medication",
        "mobility",
        "mentation",
      ];

      const idx = previous ? categories.indexOf(previous) : -1;
      if (categories[idx + 1]) {
        const next = categories[idx + 1];
        setHighlightCat(next);
        setTimeout(() => highlightNext(next), 600);
      } else {
        setHighlightCat(null);
      }
    };
    highlightNext(null);
  }

  return (
    <>
      <TabBar>
        <CategoryTabButton
          category="matters"
          activeCategory={category}
          responses={responses}
          setCategory={setCategory}
          icon={<MattersIcon />}
          highlight={highlightCat === "matters"}
        >
          What Matters?
        </CategoryTabButton>
        <CategoryTabButton
          category="medication"
          activeCategory={category}
          responses={responses}
          setCategory={setCategory}
          icon={<MedicationIcon />}
          highlight={highlightCat === "medication"}
        >
          Medication
        </CategoryTabButton>
        <CategoryTabButton
          category="mobility"
          activeCategory={category}
          responses={responses}
          setCategory={setCategory}
          icon={<MobilityIcon />}
          highlight={highlightCat === "mobility"}
        >
          Mobility
        </CategoryTabButton>
        <CategoryTabButton
          category="mentation"
          activeCategory={category}
          responses={responses}
          setCategory={setCategory}
          icon={<MentationIcon />}
          highlight={highlightCat === "mentation"}
        >
          Mentation
        </CategoryTabButton>
      </TabBar>
      {currentResponses.length === 0 && (
        <NoResponsesMessage>Select a category above...</NoResponsesMessage>
      )}
      {currentResponses.map((response, idx) => (
        <ResponseBox key={idx} onClick={() => onResponse(response, idx)}>
          {response.txt}
        </ResponseBox>
      ))}
    </>
  );
};

const NoResponsesMessage = styled.div`
  margin-top: 10px;
  font-size: 16px;
  color: #839095;
  text-align: center;
`;

const CategoryTabButton: React.FC<{
  category: FiveM;
  activeCategory: FiveM | null;
  responses: CategoryResponses;
  setCategory: (category: FiveM) => void;
  icon: ReactNode;
  children: ReactNode;
  highlight: boolean;
}> = ({
  icon,
  category,
  activeCategory,
  responses,
  setCategory,
  children,
  highlight,
}) => {
  const promptLength = (responses[category] || []).length;

  const disabled = !responses[category];
  return (
    <TabButton
      onClick={() => setCategory(category)}
      active={activeCategory === category}
      disabled={disabled}
      highlight={highlight}
    >
      {icon} ({promptLength}) {activeCategory === category && children}
    </TabButton>
  );
};
