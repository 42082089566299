import { firestore } from '../firebase';
import { doc, setDoc, getDoc, arrayUnion, updateDoc } from 'firebase/firestore';
import { GameState } from './gameStateAtom';
import { auth } from '../firebase';



interface ArchivedGameState extends GameState {
    archivedAt: Date;
}

// interface ArchivedGames {
//     games: ArchivedGameState[];
// }

export const archiveGameState = async (gameState: GameState): Promise<void> => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error('No user logged in');
    }

    const archivedDocRef = doc(firestore, 'archivedGames', user.uid);
    console.info('Archived game state doc ref:', archivedDocRef);
    const toArchive: ArchivedGameState = {
        archivedAt: new Date(),
        ...gameState
    }

    try {
        const docSnap = await getDoc(archivedDocRef);
        if (docSnap.exists()) {
            console.info('Archived game state already exists');
            await updateDoc(archivedDocRef, {
                games: arrayUnion(toArchive)
            });
        } else {
            throw new Error('Archived game state does not exist');
        }
    } catch (error) {
        console.info('Archived game state does not exist');
        await setDoc(archivedDocRef, {
            games: [
                toArchive
            ]
        });
    }






}

export const saveGameState = async (gameState: GameState): Promise<void> => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error('No user logged in');
    }

    const userDocRef = doc(firestore, 'savedGames', user.uid);

    try {
        await setDoc(userDocRef, gameState);
        console.log('Game state saved successfully');
    } catch (error) {
        console.error('Error saving game state:', error);
        throw error;
    }
};

export const loadGameState = async (): Promise<GameState | null> => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error('No user logged in');
    }

    const userDocRef = doc(firestore, 'savedGames', user.uid);

    try {
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
            return docSnap.data() as GameState;
        } else {
            console.log('No saved game state found');
            return null;
        }
    } catch (error) {
        console.error('Error loading game state:', error);
        throw error;
    }
};
