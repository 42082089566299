import React from "react";
import styled from "@emotion/styled";
import * as DialogTypes from "./Dialog";
import { ResponseBox } from "./ResponseBox";
import { CategoryResponsesPrompt } from "./CategoryResponsesPrompt";
import { CategoryResponses } from "./useDialog";
import { emrVisibleAtom } from "../state/emrAtom";
import { useRecoilState } from "recoil";
import { PatientBottomBar } from "./PatientBottomBar";
import { DoctorBottomBar } from "./DoctorBottomBar";
import { UIGlow } from "./UIGlow";

const ResponseLabel = styled.div`
  color: #fff;
  font-family: Noto Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin: 30px 16px 16px 16px;
  padding-left: 20px;
  width: 100%;
`;

interface DialogResponsesProps {
  generalResponses: DialogTypes.DialogResponse[];
  categoryResponses: CategoryResponses;
  onResponse: (response: DialogTypes.DialogResponse, idx: number) => void;
  showOptions: boolean;
  elapsedTime: number;
  emrUpdate: string | null;
  setEmrUpdate: (update: string | null) => void;
  emrInteractive: boolean;
  lastEmrUpdate: string | null;
  toggleTranscript?: () => void;
  showResponses: boolean;
  altCategoryMode: boolean;
}

export const DialogResponses: React.FC<DialogResponsesProps> = ({
  altCategoryMode,
  generalResponses,
  categoryResponses,
  onResponse,
  showOptions,
  elapsedTime,
  emrUpdate,
  setEmrUpdate,
  emrInteractive,
  lastEmrUpdate,
  toggleTranscript,
  showResponses,
}) => {
  const [emrVisible] = useRecoilState<boolean>(emrVisibleAtom);

  return (
    <>
      <ResponseLabel>You say...</ResponseLabel>

      <ScrollIntoView showContents={showResponses}>
        {generalResponses.map((response, idx) => (
          <ResponseBox key={idx} onClick={() => onResponse(response, idx)}>
            <UIGlow glowId="responseText">{response.txt}</UIGlow>
          </ResponseBox>
        ))}

        <CategoryResponsesPrompt
          responses={categoryResponses}
          onResponse={onResponse}
          altCategoryMode={altCategoryMode}
        />
      </ScrollIntoView>

      {showOptions && (
        <PatientBottomBar
          elapsedTime={elapsedTime}
          emrUpdate={emrUpdate}
          setEmrUpdate={setEmrUpdate}
          emrInteractive={emrInteractive}
          lastEmrUpdate={lastEmrUpdate}
          emrVisible={emrVisible}
          toggleTranscript={toggleTranscript}
        />
      )}

      {showOptions || <DoctorBottomBar toggleTranscript={toggleTranscript} />}
    </>
  );
};

const ScrollIntoView = styled.div<{ showContents: boolean }>`
  label: ScrollIntoView;
  overflow-y: auto;
  position: relative;
  left: ${({ showContents }) => (showContents ? "0px" : "-500px")};
  opacity: ${({ showContents }) => (showContents ? 1 : 0)};
  transition: ${({ showContents }) =>
    showContents ? "left 0.5s ease-in-out, opacity 0.5s ease-in-out" : "none"};
  align-self: stretch;
`;
