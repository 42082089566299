export interface Medication {
  name: string;
  status: "ok" | "refill" | "warning";
}

export type ScreeningIcon = "check" | "warning" | "danger";

export interface Screening {
  name: string;
  icon: ScreeningIcon;
}
export interface Problem {
  name: string;
  category: string;
}

interface SDOHQuestion {
  category: number;
  question: number;
  selected: number;
}

export interface EMR {
  name: string;
  age: number;
  sex: string;
  pronouns: string;
  allergies: string;
  medications: Medication[];
  problems: Problem[];
  screenings: Screening[];
  comments: string[];
  birthday: {
    month: number;
    day: number;
  };
  sdoh: SDOHQuestion[];
  acp: ACPData;
  fourM: ACPFourMData;
  history: EMRHistoryData[];
}

export interface EMRHistoryData {
  date: string;
  department: string;
  reason: string;
  comment: string;
}

interface ACPFourMData {
  appearance: "younger" | "same" | "older" | null;
  medication: {
    pim: "none" | "1" | "more" | null;
  };
  mentation: {
    slums: "normal" | "mild" | "positive" | null;
  };
  mobility: {
    gait: "high" | "medium" | "low" | null;
  };

  whatMatters: {
    relationships: boolean;
    healthAndWellBeing: boolean;
    personalGrowthAndDevelopment: boolean;
    purposeAndMeaning: boolean;
    financialSecurity: boolean;
    communityAndSocialConnections: boolean;
    passionsAndHobbies: boolean;
    spiritualityAndBeliefs: boolean;
    environmentAndSustainability: boolean;
    freedomAndAutonomy: boolean;
    legacyAndImpact: boolean;
    painManagement: boolean;
    comments: string;
  };
}

interface ACPData {
  onFile: boolean;
  healthcareProxy: {
    name: string;
    contact: string;
  };
  acpRequiresUpdate: boolean;
  polstOnFile: boolean;
  dnrStatus: "full" | "modified" | "no";
  dnrRequiresUpdate: boolean;
  comments: string;
}
export const setSDOHQuestion = (
  category: number,
  question: number,
  selected: number,
  emr: EMR
) => {
  const copy = { ...emr, sdoh: [...emr.sdoh] };
  const entry = copy.sdoh.find(
    (q) => q.category === category && q.question === question
  );
  if (entry) {
    entry.selected = selected;
  } else {
    copy.sdoh.push({ category, question, selected });
  }
  return copy;
};

export const getSDOHQuestion = (
  category: number,
  question: number,
  emr: EMR
) => {
  const entry = emr.sdoh.find(
    (q) => q.category === category && q.question === question
  );
  return entry ? entry.selected : -1;
};

export const EMRData: EMR[] = [
  {
    sdoh: [
      { category: 0, question: 0, selected: -1 },
      { category: 0, question: 1, selected: -1 },
      { category: 1, question: 0, selected: -1 },
      { category: 2, question: 0, selected: -1 },
    ],
    name: "Brooks, Donna",
    age: 69,
    birthday: {
      month: 12,
      day: 31,
    },
    sex: "F",
    pronouns: "she/her",
    allergies: "Shellfish",
    history: [
      {
        date: "Last Week",
        department: "ENT",
        reason: "Cerumen disimpaction",
        comment: "—",
      },
      {
        date: "Last Year",
        department: "Orthopedics",
        reason: "Rotator cuff tear",
        comment: "—",
      },
      {
        date: "Last Year",
        department: "Gastro",
        reason: "Colonoscopy",
        comment: "—",
      },
      {
        date: "Last Year",
        department: "Primary Care",
        reason: "Annual wellness",
        comment: "—",
      },
    ],

    medications: [
      {
        name: "Atorvastatin",
        status: "ok",
      },
      {
        name: "Escitalopram",
        status: "refill",
      },
      {
        name: "Tramadol 50mg Tab",
        status: "refill",
      },
      {
        name: "Prevpac pack",
        status: "ok",
      },
    ],
    problems: [
      {
        name: "Esophageal reflux",
        category: "CHRONIC",
      },
      {
        name: "Asthma NOS W/O status asthim",
        category: "OTHER",
      },
      {
        name: "Essential hypertension NOS",
        category: "OTHER",
      },
      {
        name: "Erroneous encounter",
        category: "OTHER",
      },
    ],
    screenings: [
      {
        name: "Influenza vaccine",
        icon: "check",
      },
      {
        name: "Lipid screening",
        icon: "warning",
      },
      {
        name: "Colorectal cancer screening",
        icon: "check",
      },
      {
        name: "Pneumococal vaccine",
        icon: "check",
      },
    ],
    comments: [],
    acp: {
      acpRequiresUpdate: false,
      onFile: true,
      healthcareProxy: {
        name: "John Doe",
        contact: "555-555-5555",
      },
      polstOnFile: true,
      dnrStatus: "modified",
      dnrRequiresUpdate: false,
      comments: "",
    },
    fourM: {
      appearance: null,
      medication: {
        pim: "1",
      },
      mentation: {
        slums: "normal",
      },
      mobility: {
        gait: "medium",
      },
      whatMatters: {
        relationships: false,
        healthAndWellBeing: false,
        personalGrowthAndDevelopment: false,
        purposeAndMeaning: false,
        financialSecurity: false,
        communityAndSocialConnections: false,
        passionsAndHobbies: false,
        spiritualityAndBeliefs: false,
        environmentAndSustainability: false,
        freedomAndAutonomy: false,
        legacyAndImpact: false,
        painManagement: false,
        comments: "",
      },
    },
  },
];
