import { useEffect, useState } from "react";
import { getSaves, SavedGameResult } from "./firedata";
import { GameState, gameStateAtom, initialStages } from "../state/gameStateAtom";
import { useRecoilState } from "recoil";
import { loadGameState } from "../state/saveGameService";

const initialGameState: GameState = {
  currentStage: 'core1',
  dialogContext: {
    Flags: {},
    DialogFlags: {},
    insight: 0,
    trust: 0,
  },
  dialogLog: {},
  stages: initialStages.map((stage, idx) => ({
    ...stage,
    locked: idx > 0,
  }))
}

export const useSavedGame = () => {
  const [gameState, setGameState] = useRecoilState(gameStateAtom);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadGameState().then((state) => {

      if (state) {
        setGameState(state);
      } else {
        setGameState(initialGameState);
      }
      setLoading(false);
    }).catch((error) => {

      setGameState(initialGameState);
      setLoading(false);
    });
  }, []);

  return {
    gameState,
    loading,
  };
};
