import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { EMR } from "../emr/EMR";
import { useRecoilState } from "recoil";
import {
  emrAtom,
  emrHighlightedSectionAtom,
  emrInteractiveAtom,
  emrLastUpdateAtom,
  emrSelectedTabAtom,
  emrUpdateAtom,
  emrVisibleAtom,
} from "../state/emrAtom";
import { cloneDeep, set } from "lodash";

// window.showSlide = (slide:string) => {}

const EMRContainer = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

export const DialogEMR: React.FC<{
  onClose: (nextNode: string) => void;
  show4MEmrTab?: boolean;
}> = ({ onClose, show4MEmrTab }) => {
  const [emrVisible, setEMRVisible] = useRecoilState<boolean>(emrVisibleAtom);
  const [, setEMRInteractive] = useRecoilState<boolean>(emrInteractiveAtom);
  const [nextNode, setNextNode] = useState<string | null>(null);
  const [, setEmr] = useRecoilState(emrAtom);
  const [, setEmrLastUpdate] = useRecoilState(emrLastUpdateAtom);
  const [, setEmrUpdate] = useRecoilState(emrUpdateAtom);
  const [, setEMRTab] = useRecoilState(emrSelectedTabAtom);
  const [, setEmrHighlightedSection] = useRecoilState(
    emrHighlightedSectionAtom
  );

  useEffect(() => {
    window.showEMR = (
      tab: "snapshot" | "sdoh" | "history" | "acp" | "4ms" = "snapshot",
      nextNode: string | null,
      interactive?: boolean
    ) => {
      if (!interactive) {
        setEmrLastUpdate(null);
      }
      setEMRVisible(true);
      setEMRInteractive(interactive ?? false);
      setEMRTab(tab);
      setNextNode(nextNode);
    };

    window.highlightEMRSection = (section: string | null) => {
      setEmrHighlightedSection(section);
    };

    window.updateEMR = (emrPath: string, value: any, label?: string) => {
      setEmrLastUpdate(emrPath);
      setEmr((oldEmr) => {
        label && setTimeout(() => setEmrUpdate(label));
        const newEmr = cloneDeep(oldEmr);
        return set(newEmr, emrPath, value);
      });
    };

    window.add4MNote = (note: string) => {
      console.log("add4MNote", note);
      setEmr((oldEmr) => {
        const newEmr = cloneDeep(oldEmr);
        newEmr.fourM.whatMatters.comments = newEmr.fourM.whatMatters.comments
          ? newEmr.fourM.whatMatters.comments + "\n" + note
          : note;
        return newEmr;
      });
    };

    window.resetProgressNote = () => {
      setEmrLastUpdate(null);
      setEmr((oldEmr) => {
        const newEmr = cloneDeep(oldEmr);
        newEmr.comments = [];
        return newEmr;
      });
    };

    window.addProgressNote = (note: string) => {
      setEmrLastUpdate("comments");
      setEmr((oldEmr) => {
        const newEmr = cloneDeep(oldEmr);
        newEmr.comments.unshift(note);
        return newEmr;
      });
    };

    window.hideEMR = () => {
      setEMRVisible(false);
      setEMRInteractive(true);
    };
  }, [
    setEMRInteractive,
    setEMRTab,
    setEMRVisible,
    setEmr,
    setEmrHighlightedSection,
    setEmrLastUpdate,
    setEmrUpdate,
  ]);

  const onCloseEMR = () => {
    setEMRVisible(false);
    nextNode && onClose(nextNode);
  };

  if (!emrVisible) {
    return null;
  }

  return (
    <EMRContainer>
      <EMR onClose={onCloseEMR} show4MEmrTab={show4MEmrTab} />
    </EMRContainer>
  );
};

declare global {
  interface Window {
    highlightEMRSection: (
      tab: "snapshot" | "sdoh" | "history" | "acp" | "4ms" | null
    ) => void;
    showEMR: (
      tab: "snapshot" | "sdoh" | "history" | "acp" | "4ms",
      nextNode: string | null,
      interactive?: boolean
    ) => void;
    updateEMR: (emrPath: string, value: any, label?: string) => void;
    addProgressNote: (note: string) => void;
    resetProgressNote: () => void;
    add4MNote: (note: string) => void;
    hideEMR: () => void;
  }
}
