import styled from "@emotion/styled";
import * as DialogTypes from "./Dialog";
import { SpeakerTail } from "./SpeakerTail";
import { ReactNode } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  emrInteractiveAtom,
  emrLastUpdateAtom,
  emrUpdateAtom,
} from "../state/emrAtom";
import { CategoryResponses } from "./useDialog";
import { DialogResponses } from "./DialogResponses";
import TourElement from "../game/tour/TourElement";

const PromptQuote = styled.div`
  color: #fff;
  font-family: Noto Sans;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  position: absolute;
  top: 10px;
  left: 20px;
`;

const PromptBubble = styled.div`
  position: relative;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  border-radius: 32px;
  background: rgba(0, 23, 31, 0.5);
  padding: 10px 10px 10px 60px;
  margin: 16px;
`;

const TailPosition = styled.div`
  position: absolute;
  bottom: -29px;
  right: 40px;
`;

interface DialogPromptProps {
  prompt: DialogTypes.DialogPrompt;
  generalResponses: DialogTypes.DialogResponse[];
  categoryResponses: CategoryResponses;
  open: boolean;
  elapsedTime: number;
  showOptions: boolean;
  onResponse: (response: DialogTypes.DialogResponse, idx: number) => void;
  children?: ReactNode;
  toggleTranscript?: () => void;
  altCategoryMode: boolean;
}

export const DialogPrompt: React.FC<DialogPromptProps> = ({
  prompt,
  generalResponses,
  categoryResponses,
  altCategoryMode,
  open,
  onResponse,
  elapsedTime,
  showOptions,
  children,
  toggleTranscript,
}) => {
  const [emrUpdate, setEmrUpdate] = useRecoilState(emrUpdateAtom);
  const [emrInteractive] = useRecoilState(emrInteractiveAtom);
  const lastEmrUpdate = useRecoilValue(emrLastUpdateAtom);

  return (
    <>
      {children}

      <TourElement tourId="dialogPrompt" placement="right">
        <PromptBubble id="promptBubble">
          <PromptQuote>“</PromptQuote>
          {prompt.txt}
          <TailPosition>
            <SpeakerTail />
          </TailPosition>
        </PromptBubble>
      </TourElement>

      <TourElement
        tourId="dialogChoices"
        placement="right"
        style={{ width: "100%" }}
      >
        <DialogResponses
          altCategoryMode={altCategoryMode}
          generalResponses={generalResponses}
          categoryResponses={categoryResponses}
          onResponse={onResponse}
          showOptions={showOptions}
          elapsedTime={elapsedTime}
          emrUpdate={emrUpdate}
          showResponses={open}
          setEmrUpdate={setEmrUpdate}
          emrInteractive={emrInteractive}
          lastEmrUpdate={lastEmrUpdate}
          toggleTranscript={toggleTranscript}
        />
      </TourElement>
    </>
  );
};
