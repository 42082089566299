import styled from "@emotion/styled";
import React from "react";

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #012f50;
  color: white;
  font-size: 24px;
`;

export const LoadingScreen: React.FC<{ label?: string }> = ({ label }) => {
  return <LoadingContainer>{label || "Loading..."}</LoadingContainer>;
};
