import styled from "@emotion/styled";
import { dialogOptionsBackgroundGradient } from "./dialog-styles";

export const PromptFrame = styled.div<{ open: boolean }>`
  background: ${({ open }) =>
    open ? dialogOptionsBackgroundGradient : "transparent"};

  display: flex;
  width: 500px;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  box-shadow: ${({ open }) =>
    open ? "24px 0px 24px 0px rgba(0, 23, 31, 0.25)" : "none"};

  font-family: Noto Sans;
  line-height: normal;
  position: relative;
  color: #fff;
`;
