import React from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import {
  emrHighlightedSectionAtom,
  emrLastUpdateAtom,
  emrSelectedTabAtom,
} from "../state/emrAtom";

interface EMRHighlightProps {
  emrPath: string;
  children: React.ReactNode;
}

const HighlightContainer = styled.div<{
  isSelected: boolean;
  isHighlighted: boolean;
}>`
  background-color: ${(props) =>
    props.isSelected ? "#e6f7ff" : "transparent"};
  border: ${(props) =>
    props.isSelected
      ? "3px solid #007bff"
      : props.isHighlighted
      ? "3px solid #f00"
      : "none"};

  transition: background-color 0.3s ease;
  position: relative;
  top: ${(props) => (props.isSelected || props.isHighlighted ? "-3px" : "0")};
  left: ${(props) => (props.isSelected || props.isHighlighted ? "-3px" : "0")};
  padding: ${(props) =>
    props.isSelected || props.isHighlighted ? "2px" : "0px"};
`;

const EMRHighlight: React.FC<EMRHighlightProps> = ({ emrPath, children }) => {
  const lastUpdate = useRecoilValue(emrLastUpdateAtom);
  const isSelected = !!lastUpdate?.startsWith(emrPath);

  const highlightedSection = useRecoilValue(emrHighlightedSectionAtom);
  const isHighlighted = highlightedSection === emrPath;

  return (
    <HighlightContainer isSelected={isSelected} isHighlighted={isHighlighted}>
      {children}
    </HighlightContainer>
  );
};

export default EMRHighlight;
