import React from "react";
import styled from "@emotion/styled";
import { DialogTimeElapsed } from "./DialogTimeElapsed";
import { InsightDisplay } from "./InsightDisplay";
import { EMRBanner } from "./EMRBanner";
import { DialogMenuButton } from "./DialogMenuButton";
import { DialogPatientChart } from "./DialogPatientChart";
import { TranscriptButton } from "./TranscriptButton";
import TourElement from "../game/tour/TourElement";
import { UIGlow } from "./UIGlow";

const BottomBarRow = styled.div`
  label: BottomBarRow;
  display: flex;
  align-items: top;
  justify-content: space-evenly;
  flex-shrink: 0;
  width: 100%;
  height: 82px;
  gap: 1px;
`;

const BottomBar = styled.div`
  label: BottomBar;
  width: 100%;
  position: absolute;
  bottom: -18px;
  margin-top: 5px;
  padding: 0px;
  box-sizing: border-box;
  > svg {
    flex-shrink: 0;
    flex-grow: 0;
  }
`;

const BannerContainer = styled.div`
  width: 100%;
  height: 40px;
`;

interface PatientBottomBarProps {
  elapsedTime: number;
  emrUpdate: string | null;
  setEmrUpdate: (update: string | null) => void;
  emrInteractive: boolean;
  lastEmrUpdate: string | null;
  emrVisible: boolean;
  toggleTranscript?: () => void;
}

const getEmrTab = (
  lastEmrUpdate: string | null
): "snapshot" | "sdoh" | "history" | "acp" | "4ms" => {
  if (!lastEmrUpdate) return "snapshot";
  if (lastEmrUpdate.startsWith("sdoh")) return "sdoh";
  if (lastEmrUpdate.startsWith("history")) return "history";
  if (lastEmrUpdate.startsWith("acp")) return "acp";
  if (lastEmrUpdate.startsWith("fourM")) return "4ms";
  return "snapshot";
};

export const PatientBottomBar: React.FC<PatientBottomBarProps> = ({
  elapsedTime,
  emrUpdate,
  setEmrUpdate,
  emrInteractive,
  lastEmrUpdate,
  emrVisible,
  toggleTranscript,
}) => {
  return (
    <BottomBar>
      <BottomBarRow>
        {/* <TourElement tourId="responseTimer" placement="top">
          <DialogTimeElapsed label={`${elapsedTime} Minutes`} />
        </TourElement> */}

        <TourElement tourId="insightMeter" placement="top">
          <UIGlow glowId="insightMeter">
            <InsightDisplay />
          </UIGlow>
        </TourElement>
      </BottomBarRow>
      <BannerContainer>
        {emrUpdate && (
          <EMRBanner caption={emrUpdate} onClose={() => setEmrUpdate(null)} />
        )}
      </BannerContainer>
      <BottomBarRow>
        <DialogMenuButton />

        <TourElement tourId="emrButton" placement="top">
          <UIGlow glowId="emrButton">
            <DialogPatientChart
              enabled={emrInteractive}
              onClick={() => {
                if (!emrInteractive) return;

                if (emrVisible) {
                  window.hideEMR();
                } else {
                  window.showEMR(getEmrTab(lastEmrUpdate), null, true);
                }
                setEmrUpdate(null);
              }}
            />
          </UIGlow>
        </TourElement>

        <TourElement tourId="transcriptButton" placement="top">
          <TranscriptButton onClick={toggleTranscript} />
        </TourElement>
      </BottomBarRow>
    </BottomBar>
  );
};
