import React from "react";
import styled from "@emotion/styled";
import { TranscriptButton } from "./TranscriptButton";
import TourElement from "../game/tour/TourElement";
import { UIGlow } from "./UIGlow";

const BottomBar = styled.div`
  label: BottomBar;
  width: 100%;
  position: absolute;
  bottom: -18px;
  margin-top: 5px;
  padding: 0px;
  box-sizing: border-box;
`;

const BottomBarRow = styled.div`
  label: BottomBarRow;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 82px;
`;

interface DoctorBottomBarProps {
  toggleTranscript?: () => void;
}

export const DoctorBottomBar: React.FC<DoctorBottomBarProps> = ({
  toggleTranscript,
}) => {
  return (
    <BottomBar>
      <BottomBarRow>
        <TourElement tourId="transcriptButton" placement="top">
          <UIGlow glowId="transcriptButton">
            <TranscriptButton onClick={toggleTranscript} />
          </UIGlow>
        </TourElement>
      </BottomBarRow>
    </BottomBar>
  );
};
