import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { atom, useRecoilState } from "recoil";

const svgPulseGlow = keyframes`
    0% {
        filter: drop-shadow(0 0 0px rgba(255, 255, 255, 1)) drop-shadow(0 0 20px rgba(255, 255, 255, 0.5));
    }
    50% {
        filter: drop-shadow(0 0 20px rgba(255, 255, 255, 1)) drop-shadow(0 0 30px rgba(255, 255, 255, 0.5));
    }
    100% {
        filter: drop-shadow(0 0 0px rgba(255, 255, 255, 1)) drop-shadow(0 0 20px rgba(255, 255, 255, 0.5));
    }
`;

const textPulseGlow = keyframes`
    0% {    
        text-shadow: 0 0 0px rgba(255, 255, 255, 1), 0 0 20px rgba(255, 255, 255, 0.8), 0 0 30px rgba(255, 255, 255, 0.5);
    }
    50% {
        text-shadow: 0 0 15px rgba(255, 255, 255, 1), 0 0 30px rgba(255, 255, 255, 0.8), 0 0 40px rgba(255, 255, 255, 0.5);
    }
    100% {
        text-shadow: 0 0 0px rgba(255, 255, 255, 1), 0 0 20px rgba(255, 255, 255, 0.8), 0 0 30px rgba(255, 255, 255, 0.5);
    }
`;

const GlowWrapper = styled.div`
  label: glow-wrapper;
  display: inline-block;
  animation: ${textPulseGlow} 1s infinite ease;
  > svg {
    animation: ${svgPulseGlow} 1s infinite ease;
  }
`;

export const GlowAtom = atom<string | null>({
  key: "GlowAtom",
  default: null,
});

export const UIGlow: React.FC<{
  glowId: string;
  children: React.ReactNode;
}> = ({ glowId, children }) => {
  const [activeGlowId] = useRecoilState(GlowAtom);
  if (glowId !== activeGlowId) {
    return <>{children}</>;
  }
  return <GlowWrapper>{children}</GlowWrapper>;
};
