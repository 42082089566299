import styled from "@emotion/styled";
import { logEvent } from "firebase/analytics";
import { useRef, useEffect, useState } from "react";
import { analytics } from "../firebase";
import { useTourState } from "../game/tour/TourController";

const NPCVideoPlayer = styled.video<{ visible: boolean; thumbnail: boolean }>(
  ({ visible, thumbnail }) => {
    return {
      position: "absolute",
      bottom: 0,
      right: 0,
      width: "100%",
      height: "100%",

      objectFit: "cover",
      opacity: visible ? 1 : 0,
      transition: "all 0.5s ease-in-out",
      ...(thumbnail && {
        width: 300,
        height: 200,
      }),
    };
  }
);

interface NPCVideoProps {
  visible: boolean;
  thumbnail: boolean;
  src?: string;
  idleSrc: string;
  onEnded?: () => void;
  onError?: () => void;
}

const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(2, 17, 27, 0.8);
  padding: 15px 50px; // Reduced height, increased width
  color: white;
  border-radius: 3px;
  font-size: 3em;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease; // Smooth transition for hover effect
  width: 350px;

  &:hover {
    background-color: rgba(
      4,
      34,
      54,
      1
    ); // Slightly lighter and more opaque on hover
  }
`;

export const NPCVideo: React.FC<NPCVideoProps> = ({
  visible,
  thumbnail,
  src,
  idleSrc,
  onEnded,
  onError,
}) => {
  const [playButtonVisible, setPlayButtonVisible] = useState(false);
  const idleRef = useRef<HTMLVideoElement | null>(null);
  const srcRef = useRef<HTMLVideoElement | null>(null);
  const { tourState } = useTourState();
  const isTourActive = (tourState?.tourId?.length || 0) > 0;

  const playVideos = () => {
    if (idleRef.current) {
      idleRef.current.play();
    }
    if (srcRef.current) {
      srcRef.current.play();
    }
    setPlayButtonVisible(false);
  };

  const idleRefHandler = (ref: HTMLVideoElement) => {
    if (ref) {
      idleRef.current = ref;
      ref.play().catch((err: Error) => {
        console.log("idle video error", err.message);
        logEvent(analytics, "video_error", {
          video: src,
          error: err?.message,
        });
        //setPlayButtonVisible(true);
      });
    }
  };

  const srcRefHandler = (ref: HTMLVideoElement) => {
    if (ref) {
      srcRef.current = ref;
      ref
        .play()
        .then(() => {
          setPlayButtonVisible(false);
        })
        .catch((err: Error) => {
          console.log("src video error", err.message);
          logEvent(analytics, "video_error", {
            video: src,
            error: err?.message,
          });
          setPlayButtonVisible(true);
        });
    }
  };

  return (
    <>
      {idleSrc && (
        <NPCVideoPlayer
          ref={idleRefHandler}
          src={idleSrc}
          thumbnail={thumbnail}
          loop
          muted
          visible={visible}
        />
      )}

      {src && (
        <NPCVideoPlayer
          ref={srcRefHandler}
          src={src}
          thumbnail={thumbnail}
          visible={!!src && visible}
          onEnded={onEnded}
          onError={onError}
        />
      )}
      {!isTourActive && playButtonVisible && (
        <PlayButton onClick={playVideos}>Play</PlayButton>
      )}
    </>
  );
};
