import React from "react";
import { Modal, Button } from "react-bootstrap";

interface ResetGameModalProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
}

export const ResetGameModal: React.FC<ResetGameModalProps> = ({
  show,
  onHide,
  onConfirm,
}) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ height: "30px" }}>Confirm New Game</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to start a new game? Your previous progress will
        be lost.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Start New Game
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
